import React from "react";
import { Grid, ListItemText } from "@material-ui/core";
import { Field, useFormikContext } from "formik";
import { TextField } from "formik-material-ui";
import { useIntl } from "react-intl";
import useSWR from "swr";

import { FormikSelectPreVendedor } from "../../../../components/BuscaAvancada/SelectPreVendedor";
import { FormikSelectVendedor } from "../../../../components/BuscaAvancada/SelectVendedor";
import { FormikSelectMercado } from "../../../../components/BuscaAvancada/SelectMercado";
import { FormikSelectOrigem } from "../../../../components/BuscaAvancada/SelectOrigem";
import { FormikSelectSubOrigem } from "../../../../components/BuscaAvancada/SelectSubOrigem";
import { FormikSelectDataReferencia } from "../../../../components/BuscaAvancada/SelectDataReferencia";
import { FormikSelectPais } from "../../../../components/BuscaAvancada/SelectPais";
import { FormikSelectEstado } from "../../../../components/BuscaAvancada/SelectEstado";
import { FormikSelectCidade } from "../../../../components/BuscaAvancada/SelectCidade";
import { FormikSelectDor } from "../../../../components/BuscaAvancada/SelectDor";
import { FormikSelectProduto } from "../../../../components/BuscaAvancada/SelectProduto";
import { FormikSelectEtapa } from "../../../../components/BuscaAvancada/SelectEtapa";
import { FormikSelectFunil } from "../../../../components/BuscaAvancada/SelectFunil";
import { FormikSelectMotivoDescarte } from "../../../../components/SelectMotivoDescarte";
import { FormikSelectConfirmacaoReuniao } from "../../../../components/BuscaAvancada/SelectConfirmacaoReuniao";
import { FormikSelectCampoPersonalizado } from "../../../../components/BuscaAvancada/SelectCampoPersonalizado";
import { FormikSelectGroup } from "../../../../components/SelectGroup";
import { FormikSelectOrganizacao } from "../../../../components/BuscaAvancada/SelectOrganizacao";
import { FormikSelectConversoes } from "../../../../components/SelectConversoes";
import { FormikSelectTemperatura } from "../../../../components/SelectTemperatura";
import CurrencyInput from "../../../../components/CurrencyInput";
import { useTipoPlanoSpotter } from "../../../../hooks";

const URL_CONVERSOES =
  "/api/pipeline/etapa/ListarPassagens?SomenteFiltros=true&SepararQuestionarios=true&todosFunis=true&funisAtivos=true";
export default function FormRegra() {
  const intl = useIntl();
  const { values, setFieldValue } = useFormikContext();
  const { data: campos } = useSWR("/api/pipeline/campoPersonalizado/listasParaEdicao", { suspense: true });
  const isSpotterFull = useTipoPlanoSpotter();

  return (
    <>
      <Grid container spacing={8}>
        {
          <Grid item xs={3}>
            <FormikSelectOrganizacao
              id="selectOrg-ModalBuscaAvancada"
              name="parametros.organizacoes"
              fullWidth
              type="multiple"
              getLabel={item => (
                <ListItemText
                  primary={item.descricao}
                  secondary={item.ativo ? "" : intl.formatMessage({ defaultMessage: "Inativo" })}
                />
              )}
              getInputLabel={item => item.descricao}
            />
          </Grid>
        }

        <Grid item xs={3}>
          <FormikSelectMercado fullWidth type="multiple" name="parametros.mercados" />
        </Grid>
        <Grid item xs={3}>
          <FormikSelectOrigem fullWidth type="multiple" name="parametros.origens" />
        </Grid>
        <Grid item xs={3}>
          <FormikSelectSubOrigem fullWidth type="multiple" name="parametros.subOrigens" />
        </Grid>
        <Grid item xs={3}>
          <FormikSelectPais fullWidth type="multiple" name="parametros.paises" />
        </Grid>
        <Grid item xs={3}>
          <FormikSelectEstado fullWidth type="multiple" name="parametros.estados" paises={values.parametros.paises} />
        </Grid>
        <Grid item xs={3}>
          <FormikSelectCidade
            fullWidth
            type="multiple"
            name="parametros.cidades"
            estados={values.parametros.estados}
            paises={values.parametros.paises}
          />
        </Grid>

        <Grid item xs={3}>
          <Field
            fullWidth
            component={TextField}
            name="parametros.enderecoParcial"
            id="input-endereco-parcial"
            label={intl.formatMessage({ defaultMessage: "Endereço contém" })}
          />
        </Grid>
        {isSpotterFull && (
          <>
            <Grid item xs={3}>
              <FormikSelectDor fullWidth type="multiple" name="parametros.dores" />
            </Grid>
            <Grid item xs={3}>
              <FormikSelectProduto fullWidth type="multiple" name="parametros.produtos" />
            </Grid>
          </>
        )}
        <Grid item xs={3}>
          <FormikSelectPreVendedor
            fullWidth
            type="multiple"
            name="parametros.preVendedores"
            url="/api/pipeline/usuario1/listar?tipo=PreVendedor"
          />
        </Grid>
        {isSpotterFull && (
          <>
            <Grid item xs={3}>
              <FormikSelectVendedor
                fullWidth
                type="multiple"
                name="parametros.vendedores"
                url="/api/pipeline/usuario1/listarUsuariosPVEVendedor?useLabelPV=true"
                getLabel={item => <ListItemText primary={item.descricao} secondary={item.ativo ? item.value : null} />}
                getInputLabel={item => item.descricao}
              />
            </Grid>
            <Grid item xs={3}>
              <FormikSelectFunil fullWidth type="multiple" name="parametros.funis" />
            </Grid>
          </>
        )}
        <Grid item xs={3}>
          <FormikSelectEtapa fullWidth type="multiple" name="parametros.etapas" funis={values.parametros.funis} />
        </Grid>
        <Grid item xs={3}>
          <FormikSelectDataReferencia
            id="select-etapa-referencia"
            name="parametros.dataReferencia"
            fullWidth
            type="nullable"
          />
        </Grid>
        <Grid item xs={3}>
          <Field
            fullWidth
            component={TextField}
            disabled={!Number.isInteger(values.parametros.dataReferencia)}
            InputLabelProps={{ shrink: true }}
            label={intl.formatMessage({ defaultMessage: "Data Início" })}
            type="date"
            id="select-data-inicio-referencia"
            name="parametros.dataInicio"
          />
        </Grid>
        <Grid item xs={3}>
          <Field
            fullWidth
            component={TextField}
            disabled={!Number.isInteger(values.parametros.dataReferencia)}
            InputLabelProps={{ shrink: true }}
            label={intl.formatMessage({ defaultMessage: "Data Fim" })}
            type="date"
            id="select-data-fim-referencia"
            name="parametros.dataFim"
          />
        </Grid>
        <Grid item xs={3}>
          <Field
            component={TextField}
            name="parametros.produtoLead"
            fullWidth
            label={intl.formatMessage({ defaultMessage: "Produto do lead" })}
          />
        </Grid>
        <Grid item xs={3}>
          <FormikSelectMotivoDescarte padroes={false} fullWidth type="multiple" name="parametros.motivosDescarte" />
        </Grid>
        {isSpotterFull && (
          <Grid item xs={3}>
            <FormikSelectGroup
              fullWidth
              type="multiple"
              name="parametros.grupos"
              url="/api/pipeline/grupo/listar?somenteAtivos=false"
            />
          </Grid>
        )}

        <Grid item xs={3}>
          <FormikSelectConversoes
            label={intl.formatMessage({ defaultMessage: "Conversão" })}
            fullWidth
            type="nullable"
            name="parametros.filtroQualificacao"
            url={URL_CONVERSOES}
            value={{
              tipoGate: values.parametros.filtroQualificacao.gate,
              origem: values.parametros.filtroQualificacao.etapaId,
              destino: values.parametros.filtroQualificacao.etapaDestinoId,
              questionario: values.parametros.filtroQualificacao.questionarioId,
            }}
            onChange={e =>
              setFieldValue("parametros.filtroQualificacao", {
                gate: e.target.value.tipoGate,
                etapaId: e.target.value.origem,
                etapaDestinoId: e.target.value.destino,
                questionarioId: e.target.value.questionario,
                qualificacoes: [],
              })
            }
          />
        </Grid>

        <Grid item xs={3}>
          <FormikSelectTemperatura
            disabled={!values.parametros.filtroQualificacao?.questionarioId}
            fullWidth
            name="parametros.filtroQualificacao.qualificacoes"
          />
        </Grid>
        {isSpotterFull && (
          <>
            <Grid item xs={3}>
              <FormikSelectConfirmacaoReuniao type="nullable" fullWidth name="parametros.reuniaoConfirmacao" />
            </Grid>
            <Grid item xs={3}>
              <Field
                fullWidth
                component={TextField}
                name="parametros.valorNegociacaoMinimo"
                id="input-valor-minimo"
                label={intl.formatMessage({ defaultMessage: "Valor da negociação - mínimo" })}
                InputProps={{
                  inputComponent: CurrencyInput,
                  inputProps: {
                    allowEmptyFormatting: false,
                  },
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <Field
                fullWidth
                component={TextField}
                name="parametros.valorNegociacaoMaximo"
                id="input-valor-maximo"
                label={intl.formatMessage({ defaultMessage: "Valor da negociação - máximo" })}
                InputProps={{
                  inputComponent: CurrencyInput,
                  inputProps: {
                    allowEmptyFormatting: false,
                  },
                }}
              />
            </Grid>
          </>
        )}

        {campos.map((campo, index) => (
          <Grid item xs={3} key={campo.chave}>
            <FormikSelectCampoPersonalizado
              campo={campo}
              name={`parametros.condicoesInternas.${index}.campoPersonalizado.valor`}
              id={`selectCampoPersonalizado-ModalBuscaAvancada-${index}`}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
}
