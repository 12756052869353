import React, { useState } from "react";
import PropTypes from "prop-types";
import { Dialog } from "@material-ui/core";
import { useIntl } from "react-intl";
import EditIcon from "@material-ui/icons/Edit";
import { ActionButton } from "../../../../components/ActionButton";

import ModalEditarRegra from "./ModalEditarRegra";
import useEditarRegra from "./useEditarRegra";

EditarRegra.propTypes = {
  id: PropTypes.number,
  onSave: PropTypes.func,
  customMessages: PropTypes.object,
  setEditando: PropTypes.func,
};
export default function EditarRegra({ id, onSave, customMessages, setEditando, ...props }) {
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const regra = useEditarRegra(id, save, customMessages, open);

  function openModal(e) {
    e.stopPropagation();
    setOpen(true);
    if (setEditando !== undefined) setEditando(true);
  }

  function save(novaRegra) {
    setOpen(false);
    if (setEditando !== undefined) setEditando(false);
    if (novaRegra) onSave(novaRegra);
  }

  return (
    <>
      <ActionButton
        icon={<EditIcon />}
        id={`btn-editar-regra-${id}`}
        type="text"
        tooltip={intl.formatMessage(customMessages.nomePrincipal)}
        label={intl.formatMessage(customMessages.nomePrincipal)}
        onClick={openModal}
        style={{ height: "auto" }}
        {...props}
      />

      <Dialog disableBackdropClick open={open} onClose={() => setOpen(false)} maxWidth="lg" fullWidth>
        <ModalEditarRegra id={id} customMessages={customMessages} {...regra} />
      </Dialog>
    </>
  );
}
