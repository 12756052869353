import React from "react";
import PropTypes from "prop-types";
import { Field, Form } from "formik";
import { TextField } from "formik-material-ui";
import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from "@material-ui/core";
import useSWR from "swr";
import { useIntl } from "react-intl";

import { ExactFormik } from "../../../../components";
import FormRegra from "./FormRegra";
// import useModalEditarRegra from "./useEditarRegra";

ModalEditarRegra.propTypes = {
  initialValues: PropTypes.object,
  validateParametros: PropTypes.func,
  validateNome: PropTypes.func,
  submit: PropTypes.func,
  reset: PropTypes.func,
  id: PropTypes.number,
  customMessages: PropTypes.object,
  regra: PropTypes.object,
};
export default function ModalEditarRegra({
  id,
  initialValues,
  validateParametros,
  validateNome,
  submit,
  reset,
  customMessages,
  regra,
}) {
  const intl = useIntl();
  const { data: campos } = useSWR("/api/pipeline/campoPersonalizado/listasParaEdicao");

  if ((id && !regra) || !campos) return null;

  return (
    <ExactFormik initialValues={initialValues} validate={validateParametros} onSubmit={submit} onReset={() => reset()}>
      {({ isSubmitting }) => (
        <Form>
          <DialogTitle>{intl.formatMessage(customMessages.nomePrincipal)}</DialogTitle>

          <DialogContent>
            <DialogContentText>{intl.formatMessage(customMessages.editar)}</DialogContentText>
            <Grid container spacing={8}>
              <Grid item xs={6}>
                <Field
                  autoFocus
                  fullWidth
                  component={TextField}
                  name="nome"
                  id="input-nome-regra"
                  label={intl.formatMessage(customMessages.nome)}
                  validate={validateNome}
                />
              </Grid>
            </Grid>

            <FormRegra />
          </DialogContent>

          <DialogActions>
            <Button type="reset" id="button-regra-cancelar">
              {intl.formatMessage({ defaultMessage: "Cancelar" })}
            </Button>
            <Button type="submit" id="button-regra-salvar" color="primary" disabled={isSubmitting}>
              {intl.formatMessage({ defaultMessage: "Salvar" })}
            </Button>
          </DialogActions>
        </Form>
      )}
    </ExactFormik>
  );
}
