import { defineMessage } from "react-intl";
import permissions from "../../../_common/permissions";
import { TIPO_PLANO_SPOTTER } from "../../../_common/constantes";

export const NONE = "none";

const PadraoDefFactory = () => {
  let tabOrder = -1;
  return (type, plural, singular = plural, claim, tipoPlano = null, descriptionMaxLength = 255) => ({
    tabOrder: tabOrder++,
    type,
    description: {
      plural,
      singular,
    },
    claim,
    descriptionMaxLength,
    tipoPlano,
  });
};

export const PADROES_TYPES = {
  ORIGEM: "origem",
  SUB_ORIGEM: "subOrigem",
  MERCADO: "mercado",
  PRODUTO: "produto",
  DOR: "dor",
  MOTIVO_DESCARTE: "motivoDescarte",
  RESULTADO_LIGACAO: "resultadoLigacao",
  MOTIVO_REUNIAO: "motivoReuniao",
  CARGO: "cargo",
};

const createPadraoDef = PadraoDefFactory();

const PADROES_DEF = {
  origem: createPadraoDef(
    PADROES_TYPES.ORIGEM,
    defineMessage({ defaultMessage: "Origens" }),
    defineMessage({ defaultMessage: "Origem" }),
    permissions.ORIGEM_SUBORIGEM
  ),
  subOrigem: createPadraoDef(
    PADROES_TYPES.SUB_ORIGEM,
    defineMessage({ defaultMessage: "Sub-origens" }),
    defineMessage({ defaultMessage: "Sub-origem" }),
    permissions.ORIGEM_SUBORIGEM
  ),
  mercado: createPadraoDef(
    PADROES_TYPES.MERCADO,
    defineMessage({ defaultMessage: "Mercados" }),
    defineMessage({ defaultMessage: "Mercado" }),
    permissions.MERCADO
  ),
  produto: createPadraoDef(
    PADROES_TYPES.PRODUTO,
    defineMessage({ defaultMessage: "Produtos" }),
    defineMessage({ defaultMessage: "Produto" }),
    permissions.PRODUTO,
    TIPO_PLANO_SPOTTER.FULL
  ),
  dor: createPadraoDef(
    PADROES_TYPES.DOR,
    defineMessage({ defaultMessage: "Dores" }),
    defineMessage({ defaultMessage: "Dor" }),
    permissions.DORES,
    TIPO_PLANO_SPOTTER.FULL
  ),
  motivoDescarte: createPadraoDef(
    PADROES_TYPES.MOTIVO_DESCARTE,
    defineMessage({ defaultMessage: "Motivos de Descarte" }),
    defineMessage({ defaultMessage: "Motivo de Descarte" }),
    permissions.MOTIVO_DESCARTE,
    TIPO_PLANO_SPOTTER.FULL
  ),
  resultadoLigacao: createPadraoDef(
    PADROES_TYPES.RESULTADO_LIGACAO,
    defineMessage({ defaultMessage: "Resultados de Ligação" }),
    defineMessage({ defaultMessage: "Resultado de Ligação" }),
    permissions.RESULTADO_DE_LIGACAO,
    TIPO_PLANO_SPOTTER.FULL
  ),
  motivoReuniao: createPadraoDef(
    PADROES_TYPES.MOTIVO_REUNIAO,
    defineMessage({ defaultMessage: "Motivos reunião não ocorrida" }),
    defineMessage({ defaultMessage: "Motivo reunião não ocorrida" }),
    permissions.MOTIVO_REUNIAO_NAO_OCORRIDA,
    TIPO_PLANO_SPOTTER.FULL
  ),
  cargo: createPadraoDef(
    PADROES_TYPES.CARGO,
    defineMessage({ defaultMessage: "Cargos" }),
    defineMessage({ defaultMessage: "Cargo" }),
    permissions.PADRAO_CARGOS,
    50
  ),
};

export const getPadraoDef = value => PADROES_DEF[value] || null;

export const PADROES_TAB_VIEW = Object.values(PADROES_DEF).sort((a, b) => a.tabOrder - b.tabOrder);
