import React from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { IconButton } from "@material-ui/core";
import { useIntl } from "react-intl";
import DeleteIcon from "@material-ui/icons/Delete";
import createAsyncDialog from "../../../../_common/utils/dialogConfirm/createAsyncDialog";
import { withAjaxBlackoutErrorMessage } from "../../../../_common/utils/blackout/blackout";

ExcluirRegra.propTypes = {
  id: PropTypes.number,
  onDelete: PropTypes.func.isRequired,
  customMessages: PropTypes.object,
};
export default function ExcluirRegra({ id, onDelete, customMessages }) {
  const intl = useIntl();

  async function remove(e) {
    e.stopPropagation();
    try {
      await createAsyncDialog({
        title: intl.formatMessage(customMessages.apagar),
        dialogProps: { fullWidth: true, maxWidth: "sm" },
        text: intl.formatMessage(customMessages.confirmacaoApagar),
      });
      withAjaxBlackoutErrorMessage(async () => {
        const { data: success } = await axios.post(`/api/pipeline/SegmentacaoRegra/Excluir?id=${id}`);
        onDelete(success);
      });
    } catch (err) {
      // do nothing, user declined
    }
  }

  return (
    <IconButton onClick={remove} id={`button-deletar-regra-${id}`}>
      <DeleteIcon />
    </IconButton>
  );
}
