import { defineMessages } from "react-intl";

export const messagesRegra = defineMessages({
  nomePrincipal: {
    defaultMessage: "Regra de segmentação",
  },
  nome: {
    defaultMessage: "Nome da regra",
  },
  editar: {
    defaultMessage: "Edite aqui a regra de segmentação",
  },
  semCadastro: {
    defaultMessage: "Não há regras cadastradas",
  },
  apagar: {
    defaultMessage: "Apagar regra",
  },
  confirmacaoApagar: {
    defaultMessage: "Essa operação irá excluir esta(s) regra(s) permanentemente. Deseja continuar?",
  },
  nomeNaoUnico: {
    defaultMessage: "Já existe regra com esse nome",
  },
  parametroRequerido: {
    defaultMessage: "A regra deve contar com ao menos um parâmetro",
  },
  salvar: {
    defaultMessage: "Regra salva com sucesso",
  },
});

export const messagesBusca = defineMessages({
  nomePrincipal: {
    defaultMessage: "Busca avançada",
  },
  nome: {
    defaultMessage: "Nome da busca",
  },
  editar: {
    defaultMessage: "Edite aqui a busca avançada",
  },
  semCadastro: {
    defaultMessage: "Não há buscas cadastradas",
  },
  apagar: {
    defaultMessage: "Apagar busca",
  },
  confirmacaoApagar: {
    defaultMessage: "Essa operação irá excluir esta(s) busca(s) permanentemente. Deseja continuar?",
  },
  nomeNaoUnico: {
    defaultMessage: "Já existe busca com esse nome",
  },
  parametroRequerido: {
    defaultMessage: "A busca deve contar com ao menos um parâmetro",
  },
  salvar: {
    defaultMessage: "Busca salva com sucesso",
  },
  nomeObrigatorio: {
    defaultMessage: "É preciso dar um nome a busca",
  },
  aplicarAlteracoes: {
    defaultMessage: "Deseja aplicar as alterações nessa busca?",
  },
  nomeExiste: {
    defaultMessage: "Este nome já existe, escolha outro.",
  },
});
