import React, { useState } from "react";
import PropTypes from "prop-types";
import HelpIcon from "@material-ui/icons/Help";
import { ButtonBase } from "@material-ui/core";
import { ModalDashboardInfo } from "./ModalDashboardInfo";

DashboardInfo.propTypes = {
  relatorio: PropTypes.number,
  titulo: PropTypes.string,
};

export default function DashboardInfo({ titulo, relatorio }) {
  const [modalAberta, setModalAberta] = useState(false);

  const handleOpen = () => {
    setModalAberta(true);
  };

  const handleClose = () => {
    setModalAberta(false);
  };

  return (
    <>
      <ButtonBase style={{ marginLeft: 2 }} onClick={handleOpen}>
        <HelpIcon color="action" fontSize="small" />
      </ButtonBase>
      {modalAberta && (
        <ModalDashboardInfo titulo={titulo} relatorio={relatorio} modalAberta={modalAberta} onClose={handleClose} />
      )}
    </>
  );
}
