import React from "react";
import { Switch } from "react-router";

import { ExactRoute } from "../../_common";
import routes from "../../_common/components/MainRoutes/constantes";
import { usePageTitle } from "../../hooks";

const Configuracoes = React.lazy(() => import("./Configuracoes"));
const HorarioComercial = React.lazy(() => import("./HorarioComercial"));
const MetasEPontuacoes = React.lazy(() => import("./MetasEPontuacoes"));
const Integracoes = React.lazy(() => import("./Integrations"));
const MapeamentoDeCampos = React.lazy(() => import("./Integrations/Settings/FieldMapping"));
const DefinirCriteriosParaEnvio = React.lazy(() => import("./Integrations/Settings/SetCriteriaForShipping"));
const DefinirEtapaParaEnvio = React.lazy(() => import("./Integrations/Settings/StageForShipping"));
const Padroes = React.lazy(() => import("./Padroes"));
const RegrasDetecao = React.lazy(() => import("./RegrasDetecao"));
const Filtros = React.lazy(() => import("./Filtros"));
const EditarQuestionario = React.lazy(() => import("./Filtros/EditarQuestionario/EditarQuestionario"));
const ConfiguracoesAvancadas = React.lazy(() => import("./ConfiguracoesAvancadas"));
const CamposPersonalizados = React.lazy(() => import("./CamposPersonalizados"));
const RegrasSegmentacao = React.lazy(() => import("./RegrasSegmentacao"));
const ProtecaoDados = React.lazy(() => import("./ProtecaoDados"));
const DistribuicaoLeads = React.lazy(() => import("./DistribuicaoLeads"));
const ConfiguracaoBasesLegais = React.lazy(() => import("./ProtecaoDados/ConfiguracaoBasesLegais"));
const Email = React.lazy(() => import("./Email"));
const RoteamentoInteligente = React.lazy(() => import("./RoteamentoInteligente"));

const Routes = () => {
  usePageTitle("Configurações - Exact Sales");

  return (
    <Switch>
      <ExactRoute
        exact
        path="/spotter/configuracoes/padroes"
        roles={routes.CONFIGURACOES_PADROES.roles}
        claims={routes.CONFIGURACOES_PADROES.claims}
        component={Padroes}
      />
      <ExactRoute
        path="/spotter/configuracoes/distribuicao-de-leads"
        roles={routes.CONFIGURACOES_DISTRIBUICAO_DE_LEADS.roles}
        claims={routes.CONFIGURACOES_DISTRIBUICAO_DE_LEADS.claims}
        component={DistribuicaoLeads}
      />
      <ExactRoute
        path="/spotter/configuracoes/horariocomercial"
        roles={routes.CONFIGURACOES_HORARIO_COMERCIAL.roles}
        component={HorarioComercial}
      />
      <ExactRoute
        path="/spotter/configuracoes/metas"
        roles={routes.CONFIGURACOES_METAS.roles}
        component={MetasEPontuacoes}
      />
      <ExactRoute
        path="/spotter/configuracoes/integracoes"
        roles={routes.CONFIGURACOES_INTEGRACOES.roles}
        component={Integracoes}
      />
      <ExactRoute
        path="/spotter/configuracoes/regras-deteccao"
        roles={routes.CONFIGURACOES_REGRAS_DETECCAO.roles}
        component={RegrasDetecao}
      />
      <ExactRoute
        path="/spotter/configuracoes/mapeamentocampos/:plataforma"
        roles={routes.CONFIGURACOES_MAPEAMENTO_CAMPOS.roles}
        component={MapeamentoDeCampos}
      />
      <ExactRoute
        path="/spotter/configuracoes/criteriosenvio/:plataforma"
        roles={routes.CONFIGURACOES_CRITERIOS_ENVIO.roles}
        component={DefinirCriteriosParaEnvio}
      />
      <ExactRoute
        path="/spotter/configuracoes/etapaenvio/:plataforma"
        roles={routes.CONFIGURACOES_ETAPA_ENVIO.roles}
        component={DefinirEtapaParaEnvio}
      />
      <ExactRoute
        path="/spotter/configuracoes/filtros"
        exact
        roles={routes.CONFIGURACOES_FILTROS.roles}
        component={Filtros}
      />
      <ExactRoute
        path="/spotter/configuracoes/filtros/:questionarioId"
        roles={routes.CONFIGURACOES_FILTROS.roles}
        component={EditarQuestionario}
      />
      <ExactRoute
        path="/spotter/configuracoes/configuracoes-avancadas"
        roles={routes.CONFIGURACOES_CONFIGURACOES_AVANCADAS.roles}
        component={ConfiguracoesAvancadas}
      />
      <ExactRoute
        path="/spotter/configuracoes/campos-personalizados/lead"
        roles={routes.CONFIGURACOES_CAMPOS_PERSONALIZADOS_LEAD.roles}
        component={CamposPersonalizados}
      />
      <ExactRoute
        path="/spotter/configuracoes/campos-personalizados/organizacao"
        roles={routes.CONFIGURACOES_CAMPOS_PERSONALIZADOS_ORGANIZACAO.roles}
        component={CamposPersonalizados}
      />
      <ExactRoute
        path="/spotter/configuracoes/email"
        exact
        roles={routes.CONFIGURACOES_EMAIL.roles}
        component={Email}
      />
      <ExactRoute
        path="/spotter/configuracoes/regras-segmentacao"
        roles={routes.CONFIGURACOES_REGRAS_SEGMENTACAO.roles}
        component={RegrasSegmentacao}
      />
      <ExactRoute
        path="/spotter/configuracoes/protecao-de-dados"
        roles={routes.CONFIGURACOES_PROTECAO_DADOS.roles}
        component={ProtecaoDados}
      />
      <ExactRoute
        path="/spotter/configuracoes/configuracao-bases-legais"
        exact
        roles={routes.CONFIGURACOES_BASES_LEGAIS.roles}
        component={ConfiguracaoBasesLegais}
      />
      <ExactRoute
        path="/spotter/configuracoes/roteamento-inteligente"
        roles={routes.CONFIGURACOES_ROTEAMENTO_INTELIGENTE.roles}
        component={RoteamentoInteligente}
      />
      <ExactRoute path="/spotter/configuracoes" exact roles={routes.CONFIGURACOES.roles} component={Configuracoes} />
    </Switch>
  );
};

export default Routes;
