import { useMemo, useState, useEffect } from "react";
import merge from "lodash.merge";
import { set } from "dot-prop-immutable";
import moment from "moment";
import { defineMessages, useIntl } from "react-intl";
import axios from "axios";
import useSWR from "swr";

import { createSnackbar, AjaxBlackout } from "../../../../_common";
import { removeEmptyFields } from "../../../../_common/utils/object/object";

export function clearEmptyFields(form) {
  let values = form;

  // tira condições internas sem valor
  if (form.parametros?.condicoesInternas)
    values = set(
      form,
      "parametros.condicoesInternas",
      form.parametros.condicoesInternas.filter(({ campoPersonalizado: { valor } }) => !!valor)
    );

  // tira filtro qualificação sem questionário
  if (form.parametros?.filtroQualificacao)
    values = set(
      values,
      "parametros.filtroQualificacao",
      form.parametros.filtroQualificacao?.questionarioId ? form.parametros.filtroQualificacao : undefined
    );

  // tira arrays e strings vazias
  return removeEmptyFields(values);
}

export function initializeValues(regra, campos) {
  return merge(
    {},
    DEFAULT_REGRA,
    regra,
    {
      parametros: {
        condicoesInternas: campos?.map(campo => {
          const valor =
            regra?.parametros?.condicoesInternas?.find(c => c.campoPersonalizado?.id === campo.id)?.campoPersonalizado
              ?.valor ?? "";

          return {
            logica: 1,
            campoPersonalizado: { id: campo.id, tipo: campo.tipo, valor },
          };
        }),
      },
    },
    {
      parametros: {
        dataInicio: regra?.parametros?.dataInicio ? moment(regra.parametros.dataInicio).format("YYYY-MM-DD") : "",
        dataFim: regra?.parametros?.dataFim ? moment(regra.parametros.dataFim).format("YYYY-MM-DD") : "",
      },
    }
  );
}

const messages = defineMessages({
  NOME_REQUIRED: { defaultMessage: "Campo obrigatório *" },
  NOME_NOT_UNIQUE: { defaultMessage: "Já existe regra com esse nome" },
  QUALIFICACAO_REQUIRED: { defaultMessage: "É necessário informar ao menos uma qualificação" },
  PARAMETRO_REQUIRED: { defaultMessage: "A regra deve contar com ao menos um parâmetro" },
  FIELD_REQUIRED: { defaultMessage: "Campo obrigatório *" },
});

export function makeValidateNome(regra, regras, intl, customMessage) {
  return value => {
    if (!regra) return undefined;

    const nome = value.trim();
    if (!nome) return intl.formatMessage(messages.NOME_REQUIRED);
    const nomeNotUnique = regras
      .filter(r => r.id !== regra.id)
      .filter(r => r.usuarioId === regra.usuarioId)
      .some(r => r.nome === nome);
    if (nomeNotUnique)
      return customMessage === undefined
        ? intl.formatMessage(messages.NOME_NOT_UNIQUE)
        : intl.formatMessage(customMessage);

    return undefined;
  };
}

export function makeValidateParametros(intl) {
  return ({ parametros }) => {
    let errors = {};

    // deve ter dataInicio se tiver etapaReferencia
    if (parametros.dataReferencia && !parametros.dataInicio) {
      errors = set(errors, "parametros.dataInicio", intl.formatMessage(messages.FIELD_REQUIRED));
    }

    // deve ter dataFim e dataFim se tiver etapaReferencia
    if (parametros.dataReferencia && !parametros.dataFim) {
      errors = set(errors, "parametros.dataFim", intl.formatMessage(messages.FIELD_REQUIRED));
    }

    // deve ter temperatura se tem questionário selecionado
    const temFiltro = !!parametros.filtroQualificacao?.questionarioId;
    const faltaQualificaco = !parametros.filtroQualificacao.qualificacoes.length;
    if (temFiltro && faltaQualificaco) {
      const message = intl.formatMessage(messages.QUALIFICACAO_REQUIRED);
      errors = set(errors, "parametros.filtroQualificacao", message);
    }
    // arrays com itens e booleanos são valores válidos, ao menos 1 é necessário
    const { condicoesInternas, logica, filtroQualificacao, ...valores } = parametros;
    const semParametros = Object.values(valores).every(
      v => (Array.isArray(v) ? !v.length : [null, undefined, ""].includes(v))
    );
    const semCamposPersonalizados = condicoesInternas.every(c => !c.campoPersonalizado.valor);

    if (!temFiltro && semParametros && semCamposPersonalizados) {
      errors = set(errors, "nome", intl.formatMessage(messages.PARAMETRO_REQUIRED));
    }

    return errors;
  };
}

export async function saveRegra(form) {
  const values = clearEmptyFields(form);

  const { data: id } = await axios.post("/api/pipeline/SegmentacaoRegra/Salvar", values);
  return { ...values, id };
}

export default function useEditarRegra(id, onSave, customMessages, buscarDados = false) {
  const intl = useIntl();
  const [regra, setRegra] = useState(null);
  const { data: regrasGerais } = useSWR("/api/pipeline/SegmentacaoRegra/Listar");
  const { data: campos } = useSWR("/api/pipeline/campoPersonalizado/listasParaEdicao");

  useEffect(
    () => {
      if (buscarDados && id && regra === null) buscarRegra();
    },
    [buscarDados]
  );
  const buscarRegra = () => {
    AjaxBlackout.Show();
    axios
      .get(`/api/pipeline/segmentacaoRegra/buscar?id=${id}`)
      .then(resp => setRegra(resp.data))
      .finally(AjaxBlackout.Hide());
  };

  const initialValues = useMemo(() => initializeValues(regra, campos), [regra, campos]);
  const validateNome = useMemo(() => makeValidateNome(initialValues, regrasGerais, intl, customMessages.nomeNaoUnico), [
    initialValues,
    regrasGerais,
    intl,
    customMessages.nomeNaoUnico,
  ]);
  const validateParametros = useMemo(() => makeValidateParametros(intl), [intl]);

  async function submit(form) {
    const novaRegra = await saveRegra(form);
    buscarRegra();
    onSave(novaRegra);
    createSnackbar(intl.formatMessage(customMessages.salvar));
  }

  return { validateParametros, validateNome, submit, reset: () => onSave(), initialValues, regra };
}

export const DEFAULT_REGRA = {
  nome: "",
  parametros: {
    logica: 1,
    organizacoes: [],
    condicoesInternas: [],
    mercados: [],
    origens: [],
    subOrigens: [],
    paises: [],
    estados: [],
    cidades: [],
    preVendedores: [],
    vendedores: [],
    funis: [],
    etapas: [],
    produtoLead: "",
    enderecoParcial: "",
    motivosDescarte: [],
    reuniaoConfirmacao: "",
    dores: [],
    produtos: [],
    grupos: [],
    dataReferencia: "",
    dataInicio: "",
    dataFim: "",
    conversoes: "",
    filtroQualificacao: {
      qualificacoes: [],
      etapaId: "",
      questionarioId: "",
      temperaturas: [],
    },
    valorNegociacaoMinimo: null,
    valorNegociacaoMaximo: null,
  },
};
