import React from "react";
import PropTypes from "prop-types";
import { Button, DialogContent, DialogContentText, DialogActions, Dialog, DialogTitle } from "@material-ui/core";
import { useIntl } from "react-intl";
import html from "react-inner-html";
import useSWR from "swr";
import { Loading } from "../../../../_common";

export function ModalDashboardInfo({ modalAberta, onClose, titulo, relatorio }) {
  const intl = useIntl();
  const { data: dados, isValidating } = useSWR(`/api/pipeline/dashboard/info?relatorio=${relatorio}`);

  return (
    <>
      <Dialog maxWidth="md" open={modalAberta}>
        <DialogTitle>{titulo}</DialogTitle>
        <Loading isLoading={isValidating}>
          <DialogContent>
            <DialogContentText color="textPrimary" {...html(dados)} />
          </DialogContent>
        </Loading>
        <DialogActions>
          <Button onClick={onClose}>{intl.formatMessage({ defaultMessage: "Fechar" })}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

ModalDashboardInfo.propTypes = {
  modalAberta: PropTypes.bool,
  onClose: PropTypes.func,
  relatorio: PropTypes.number,
  titulo: PropTypes.string,
};

export default ModalDashboardInfo;
